* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#bg {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
    pointer-events: none;
}

.logo {
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -34%);
    z-index: 100;
}

.lim_nav {
    position: fixed;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 90%;
    margin-bottom: 3%;
    background-color: white;
    border-radius: 1rem;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.3));
    backdrop-filter: blur(5rem);
    z-index: 100;
}

.cont {
    position: fixed;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 60%;
    min-height: 40vh;
    background-color: white;
    border-radius: 1rem;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.3));
    z-index: 100;
}

.cont h1 {
    margin-bottom: 1rem;
}

.cont p {
    margin-bottom: 2rem;
    font-size: larger;
}



.typewriter {
    width: 50%;
    z-index: 100;
}

.typewriter h1 {
    overflow: hidden;
    border-right: .15em solid orange;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .16em;
    animation:
        typing 2.5s steps(23, end),
        blink-caret .75s step-end infinite;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent}
    50% { border-color: orange;}
}