* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style-type: none;
}

.home {
    min-height: 100vh;
    /* background: linear-gradient(to right top, #bff097, #6fd6ff); */
    /* background: linear-gradient(to right top, lightgrey, #6fd6ff); */
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.in_header {
    background-color: white;
    border-radius: 1rem;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.3));
    margin-bottom: 2rem;
    width: 90%;
    z-index: 100;
    position: fixed;
    top: 0%;
    backdrop-filter: blur(2rem);
    max-width: 100rem;
    
}

.user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 20rem;
    margin-top: 5%;
    z-index: 2;
    /* background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4)); */
    /* background: linear-gradient(to right top, rgba(191,240,151,1), rgba(111, 214, 255, .5)); */
    background: none;
    border-radius: 50%;
}

.user h1 {
    font-size: medium;
}

.request {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    background-color: white;
    margin-left: 3%;
    margin-right: 3%;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4));
    border-radius: 30px;
    padding-top: 2%;
    padding-bottom: 2%;
    z-index: 2;
    backdrop-filter: blur(2rem);
    max-width: 100rem;

}

.request_form {
    width: 70%;
    padding-left: 2%;
    padding-right: 2%;
}

.form_input {
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.3));
    border-radius: 30px;
}

.my_readings {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 90%;
    background-color: white;
    margin-top: 3%;
    margin-left: 3%;
    margin-right: 3%;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.3));
    border-radius: 30px;
    padding-top: 2%;
    padding-bottom: 2%;
    z-index: 2;
    backdrop-filter: blur(2rem);
    max-width: 100rem;
}

.audio_toast {
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
    border-radius: 30px;
    padding-top: 2%;
    padding-bottom: 2%;
}

.toast_head {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: rgba(255, 255, 255, 0.1);
}

audio::-webkit-media-controls-panel {
    background: linear-gradient(to right top, rgba(191,240,151,1), rgba(111, 214, 255, .5));
}


.circle1, .circle2, .circle3, .circle4, .circle5 {
    height: 20rem;
    width: 20rem;
    border-radius: 50%;
    position: fixed;
}

.circle1 {
    background: linear-gradient(to right top, #bff097, #6fd6ff);
    top: -10%;
    left: -7%;
}

.circle2 {
    background: linear-gradient(to left bottom, #bff097, #6fd6ff);
    top: 20%;
    right: -2%;
}

.circle3 {
    background: linear-gradient(to left top, #bff097, #6fd6ff);
    top: 45%;
    right: 50%;
}

.circle4 {
    background: linear-gradient(to left top, #bff097, #6fd6ff);
    bottom: -10%;
    right: 15%;
}

.circle5 {
    background: linear-gradient(to right top, #bff097, #6fd6ff);
    bottom: -20%;
    left: -5%;
}

.beta_badge {
    background-color: white;
    background: linear-gradient(to right top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
}

.table_row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.read_table {
    /* background-color: rgba(190,240,153,.6); */
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(1rem);
    width: 90%;
    border-radius: 2rem;
}

.listen {
    background-color: white;
    background: linear-gradient(to right top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    color: grey;
    border: none;
    border-radius: 20px;
    
}

.listen:hover {
    background: linear-gradient(to right top, rgba(191,240,151,1), rgba(111, 214, 255, .5));

}

.listen:disabled {
    color: gray;
}

#ctrd {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}